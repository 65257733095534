<template>
  <div class="content">
    <sub-title>추천인 현황</sub-title>
    <table class="utable" style="width: 100%;">
      <tr>
        <th style="text-align: center; width: 25%;">닉네임</th>
        <th style="text-align: center;width: 25%">최종접속시간</th>
        <th style="text-align: center;width: 25%">가입일</th>
      </tr>
      <tr v-for="(item,index) in referrerUsers" :key="index">
        <td style="" align="center">{{item.nickname}}</td>
        <td style="" align="center">{{item.lastLoginTime|datef('MM월 DD일')}}</td>
        <td style="" align="center">{{item.createTime|datef('MM월 DD일')}}</td>
      </tr>
    </table>

    <!--페이지-->
    <pagination :page-index="pageNum"
                :total="total"
                :page-size="pageSize"
                @change="pageChange"></pagination>

    </div>


</template>


<script>

import {referrerUsers} from "@/network/userRequest";
import {checkLoginOnCreateMinxin, postionMixin} from "@/common/mixin";
    import sportsConst from "@/common/sportsConst";
    import SubTitle from "@/views/afront/zero/SubTitle.vue";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";

    export default {
      name: "RefUsers",
      mixins: [postionMixin,checkLoginOnCreateMinxin],
      components: {  SubTitle },
      data() {
        return {
          referrerUsers: [],
          pageNum: 1,
          pageSize: 20,
          total: 1,
          sportsConst,
          position: "추천인정보",
        }
      },
      methods: {
        getRefUserInfo() {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          referrerUsers(this.pageNum, this.pageSize).then(res => {
            this.$store.commit(RECEIVE_HIDE_LOADING)
            if (res.data.success) {
              this.total = res.data.total
              this.referrerUsers = res.data.data
            }
          })
        },
        //pagination.vue 에서 페이지 클릭 시 emit발송접수 메소드
        pageChange(page) {
          this.pageNum = page
          this.getRefUserInfo()
        },
      },
      created() {
        this.getRefUserInfo()
      }
    }
</script>

<style scoped>
    @import url("../../../assets/css/front/afrontrefusers.css");

    .user-info {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      justify-content: center;
      text-align: left;
    }

    .user-info span {
      display: inline-block;
      border-bottom: 1px solid #060607;
      font-size: 14px;
      line-height: 30px;
    }

    .user-info .tk {
      width: 40%;
      background-color: var(--noticeBg);
      text-align: center;
      color: #fff9ff;
    }

    .user-info .t {
      width: 60%;
      background-color: var(--noticeBg2);
      padding-left: 10px;
      box-sizing: border-box;
      color: #ffffff;
    }

    .user-info input {
      background-color: #e0e0e0;
      height: 20px;
      color: #1c1c25;
      padding-left: 6px;
    }

    @media screen and (max-width: 800px) {
      input {
        width: 100%;
      }
    }
</style>